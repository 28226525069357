import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
Vue.config.productionTip = false

// 引入公共css
import './assets/style/font.css';
import './assets/style/reset.css';
// 引入公共组件
import mComponents from './components'
Vue.use(mComponents)

Vue.use(ElementUI, { locale });
import resetMessage from './assets/utils/reseMessage';
Vue.prototype.$message = resetMessage;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
