<template>
    <!-- 吸顶容器 -->
    <div class="left" :class="{show:isShow}">
      <div class="left_box">
        <div class="left_b" @mouseover="isleft=true" @mouseleave="isleft=false">
          <!-- <img src="../../views/home/images/lefta.jpg" alt=""> -->
          <div class="erwm" v-if="isleft">
            <!-- <img src="../../views/home/images/what.jpg" alt=""> -->
          </div>
        </div>
        <div class="left_b" @click="goTop"></div>
        <div class="left_b" @click="goTopb"></div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        isShow:true,
        isleft:false,
    };
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.isShow = scrollTop > 780 ? true : false 
    },
    goTop(){
        document.querySelector('#onTop').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    },
    goTopb(){
       
    },
  },
};
</script>
<style lang='less' scoped>
// 吸顶
.left{
  position: fixed;
  top: 70%;
  right: 10px;
  z-index: 999;
  opacity: 0;
  display: flex;
  align-items: center;
}
.show{
  opacity: 1;
}

.left_b{
  position: relative;
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
  background: green;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 32px 32px 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.left_b img{
  width: 40px;
  height: 40px;
}
.erwm{
  position: absolute;
  top: -12px;
  left: -122px;
  width: 100px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 6px 10px 20px 0px rgba(192,195,204,0.2);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.erwm::after{
    border: 9px solid transparent;
    border-left: 9px solid #FFFFFF;
    content: "";
    display: block;
    position: absolute;
    right: -18px;
    top: 38px;
    z-index: 2;
}
.erwm img{
  width: 80px;
  height: 80px;
}

</style>
