<template>
  <div class="home">
    <!-- 导航栏 -->
    <div class="header" :class="{header_scrolled:isShow}">
      <div class="logo"><img src="./image/logo.png" alt=""></div>
      <div class="headment">
        <div class="head_close" v-if="isclose" @click="isclose=false"></div>
        <div class="head_img" v-else @click="isclose=true"></div>
        <div class="head_mentbox" v-if="isclose" :class="{header_scrolled:isShow}">
          <div class="mentli" v-for="(item,index) in navList" :key="index" @click="onNavClick(item.url,index)" :class="{isment:navIndex==index}">{{item.name}}</div>
        </div>
      </div>
      <div class="headnav">
        <div class="navli" v-for="(item,index) in navList" :key="index" @click="onNavClick(item.url,index)" :class="{isnav:navIndex==index}">{{item.name}}</div>
      </div>
    </div>
    <!-- 第一屏 -->
    <div class="banner" id="section1">
      <div class="banner_tit">Specialize in Improving Chinese Scores of Singapore Primary Students</div>
      <div class="banner_val">专注新加坡小学<br />华文作文成绩提升</div>
      <div class="banner_bot"><img src="./image/bot.png" alt="" @click="onNavClick('section2',1)"></div>
    </div>
    <!-- 第二屏 -->
    <div id="section2" class="banb">
      <div class="content">
        <div class="title">我们的优势</div>
        <div class="banb_con">
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advanta.png"></div>
            <div class="banb_text">2-4 人小班教学，<br />关注每一个孩子！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advantb.png"></div>
            <div class="banb_text">8-12 人大班，<br /> 超低价格，性价比高！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advantc.png"></div>
            <div class="banb_text">回放反复学，<br /> 提供长达 3 个月的课程回放！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advantd.png"></div>
            <div class="banb_text">老师提供功课批改讲解，<br /> 有问必答！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advante.png"></div>
            <div class="banb_text">课上引导孩子情景再现，<br />  加强知识点理解！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advantf.png"></div>
            <div class="banb_text">从头到尾都是同一个老师，<br /> 更懂孩子。</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advantg.png"></div>
            <div class="banb_text">提供教材，<br />  精准查漏补缺！</div>
          </div>
          <div class="banb_box">
            <div class="banb_img"><img src="./image/advanth.png"></div>
            <div class="banb_text">孩子课堂学习情况，<br /> 课后必反馈，事事有回应！</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三屏 -->
    <div id="section3" class="banc">
      <div class="content">
        <div class="title">我们的特色</div>
        <div class="banc_con">
          <!-- 轮播图-->
          <swiper ref="mySwiper" class="swiper swipera" :options="swiperOptions">
            <!-- <swiper-slide v-for="(item, index) in sliderList" :key="index">
              <img class="banner" :src="item.image">
            </swiper-slide> -->
            <swiper-slide class="banc_box">
              <div class="banc_img"><img src="./image/chara.png" alt=""></div>
              <div class="banc_text">中新汉语专业教师培养体系 <br /> 严格选拔功底深厚，认真负责的好老师</div>
            </swiper-slide>
            <swiper-slide class="banc_box">
              <div class="banc_img"><img src="./image/charb.png" alt=""></div>
              <div class="banc_text">主讲老师/学管老师/班主任 N 对 1 服务体系 <br /> 为每位学员定制个性化成长路径</div>
            </swiper-slide>
            <swiper-slide class="banc_box">
              <div class="banc_img"><img src="./image/charc.png" alt=""></div>
              <div class="banc_text">独家自研发教材 <br /> 随时更新迭代迅速，紧跟 MOE 教学大纲</div>
            </swiper-slide>
            <swiper-slide class="banc_box">
              <div class="banc_img"><img src="./image/chard.png" alt=""></div>
              <div class="banc_text">超高性价比 <br /> 拒绝高额广告费和教室租金 <br /> 用平价“线上课程”提供高品质教学服务</div>
            </swiper-slide>
            <swiper-slide class="banc_box">
              <div class="banc_img"><img src="./image/chare.png" alt=""></div>
              <div class="banc_text">多级别进阶教学 <br /> 针对程度不同的孩子分班学习</div>
            </swiper-slide>
          </swiper>
          <div class="mjs_arrow prev" @click="prevFn('mySwiper')">
          </div>
          <div class="mjs_arrow next" @click="nextFn('mySwiper')">
          </div>
        </div>
      </div>
    </div>
    <!-- 第四屏 -->
    <div id="section4" class="band">
      <div class="content">
        <div class="titleb">课堂展示</div>
        <div class="band_con">
          <div class="band_type">
            <div class="band_li" v-for="(item,index) in bandList" :key="index" :class="{band_active:bandIndex==index}" @click="onbandClick(index)">{{item.name}}</div>
          </div>
          <div class="band_box" v-if="bandIndex==0">
            <div class="band_top">
              <video
                    ref='videoa'
                    id="videoRef"
                    class="videoI"
                    controls="controls"
                    poster="./image/videoa.jpg"
                    src="https://www.zxmandarin.com/uploads/video1.mp4"
                ></video>
              <!-- <div class="band_play" @click="handlePlay" v-if="!isplay"><img src="./image/play.png" alt=""></div> -->
            </div>
            <div class="band_text">精细讲解观察方法、逐个分析画面人物、五官表情细致讲解</div>
          </div>
          <div class="band_box" v-else-if="bandIndex==1">
            <div class="band_top">
              <video
                    ref='videoa'
                    id="videoRef"
                    class="videoI"
                    controls="controls"
                    poster="./image/videob.jpg"
                    src="https://www.zxmandarin.com/uploads/video2.mp4"
                ></video>
              <!-- <div class="band_play" @click="handlePlay" v-if="!isplay"><img src="./image/play.png" alt=""></div> -->
            </div>
            <div class="band_text">情景演绎小游戏、学生参与互动表演、老师引导学生描述</div>
          </div>
          <div class="band_box" v-else>
            <div class="band_top">
              <video
                    ref='videoa'
                    id="videoRef"
                    class="videoI"
                    controls="controls"
                    poster="./image/videoc.jpg"
                    src="https://www.zxmandarin.com/uploads/video3.mp4"
                ></video>
              <!-- <div class="band_play" @click="handlePlay" v-if="!isplay"><img src="./image/play.png" alt=""></div> -->
            </div>
            <div class="band_text">课堂小练笔、提炼文章结构、举一反三讲解</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第五屏 -->
    <div id="section5" class="bane">
      <div class="contentb">
        <div class="titleb">课程简介</div>
        <div class="bane_con">
          <div class="bane_type">
            <div class="bane_li" v-for="(item,index) in baneList" :key="index" :class="{bane_active:baneIndex==index}" @click="onbaneClick(index)">{{item.name}}</div>            
          </div>
          <div class="bane_top" v-if="baneIndex==0">
            <div class="bane_box" @click="handDialoge(dialogeLista)">
              <div class="bane_title">PSLE 作文专项提分课</div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">大班人数：8-12 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：90mins</div>
                <div class="bane_i">适合年级：P4-P6</div>
                <div class="bane_i">课时：<span>10节</span></div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
            <div class="bane_box" @click="handDialoge(dialogeListb)">
              <div class="bane_title">PSLE 阅读理解提分课</div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">大班人数：8-12 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：90mins</div>
                <div class="bane_i">适合年级：P4-P6</div>
                <div class="bane_i">课时：<span>7节</span></div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
            <div class="bane_box" @click="handDialoge(dialogeListc)">
              <div class="bane_title">创意作文写作课</div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">大班人数：8-12 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：90mins</div>
                <div class="bane_i">适合年级：P3-P6</div>
                <div class="bane_i">课时：<span>20节</span></div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
          </div>
          <div class="bane_top" v-else>
            <div class="bane_box" @click="handDialoge(dialogeListd)">
              <div class="bane_title">基础作文综合课 <div class="bane_text">（基础字词、兴趣培养）</div></div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：55mins</div>
                <div class="bane_i">适合年级：<span>P1-P2</span></div>
                <div class="bane_i">课时：15节</div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
            <div class="bane_box" @click="handDialoge(dialogeListe)">
              <div class="bane_title">进阶作文综合课<div class="bane_text">（基础字词、阅读理解、归纳总结）</div></div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">大班人数：8-12 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：90mins</div>
                <div class="bane_i">适合年级：<span>P3-P4</span></div>
                <div class="bane_i">课时：15节</div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
            <div class="bane_box" @click="handDialoge(dialogeListf)">
              <div class="bane_title">强化作文综合课 <div class="bane_text">（字词复习、阅读理解、归纳总结、高分词句、优化表达）</div></div>
              <div class="bane_body">
                <div class="bane_i">小班人数：2-4 人</div>
                <div class="bane_i">大班人数：8-12 人</div>
                <div class="bane_i">课程安排：每周 1 节</div>
                <div class="bane_i">课程时长：90mins</div>
                <div class="bane_i">适合年级：<span>P5-P6</span></div>
                <div class="bane_i">课时：15节</div>
              </div>
              <div class="bane_btn">进一步了解</div>
            </div>
          </div>
          <div class="bane_bom">
            <div class="bane_tit">教学特色</div>
            <div class="bane_val">我们主打作文版块的提升，很大一部分原因在于，作文是孩子综合能力的体现，也是最与社会接轨的一个版块。孩子在作文训练的同时，很大程度上也能提高阅读理解、口试、听力的分数。我们的课堂以强互动为主，让孩子主动思考、主动开口、主动表达、主动犯错，在此基础上，才能全方位的提高孩子的学习成绩。区别于传统的教学方式，我们强互动的教学方式，更加适合新加坡的孩子，因为无论学校还是校外机构，都大量的强调死记硬背的方式，对于自控力强的孩子也许有用，但是对大部分自控力不强的孩子反而起效甚微。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第五屏弹窗 -->
    <div class="banedialog" v-if="isdialoge">
      <div class="dialoge_box">
        <div class="dialoge_title">{{dialogeList.title}}</div>
        <div class="dialoge_tle" v-if="dialogeList.tle">{{dialogeList.tle}}</div>
        <div class="dialoge_body">
          <div class="dialoge_tit">课程内容：</div>
          <div class="dialoge_val" v-for="(obj,index) in dialogeList.val" :key="index">{{obj}}</div>
          <div class="dialoge_check" v-if="dialogeList.check">
            <div class="dialoge_img" v-for="(age,index) in dialogeList.check" :key="index"><img src="./image/check.png" alt="">{{age}}</div>
          </div>
          <div class="dialoge_tit" style="margin-top: 20px;">课程特点：</div>
          <div class="dialoge_val">{{dialogeList.characteristic}}</div>
        </div>
        <div class="dialoge_btn" @click="isdialoge=false">我知道了</div>      
      </div>
    </div>
    <!-- 第六屏 -->
    <div id="section6" class="banf">
      <div class="banf_prev" @click="reviewsClick('prev')"></div>
      <el-carousel ref="bane" arrow="never" indicator-position="outside" trigger="click" height="700px">
        <el-carousel-item>
          <div class="banf_box">
            <div class="banf_top">讲师介绍 <div class="banf_val">中新汉语全明星讲师阵容</div></div>
            <div class="banf_bom">
              <div class="banf_bt">
                <div class="banf_name">邓秋红老师</div>
                <div class="banf_li">- 中新汉语新加坡小学金牌讲师</div>
                <div class="banf_li">- 专注汉语国际教育</div>
                <div class="banf_li">- 中新汉语教研组长</div>
                <div class="banf_li">- 10多年教学经验，超过13K+小时的教学经验</div>
                <div class="banf_li">- 擅长提高PSLE作文成绩，引导孩子自信学习</div>
                <div class="banf_img"><img src="./image/teachera.png" alt=""></div>
              </div>
            </div>
            
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banf_box">
            <div class="banf_top">讲师介绍 <div class="banf_val">中新汉语全明星讲师阵容</div></div>
            <div class="banf_bom">
              <div class="banf_bt">
                <div class="banf_name">李倩老师</div>
                <div class="banf_li">- 中新汉语新加坡小学金牌讲师   </div>
                <div class="banf_li">- 专注新加坡小学华文综合成绩提高</div>
                <div class="banf_li">- 9年教育从业经验、8000+课时教学时长</div>
                <div class="banf_li">- 擅长针对孩子精细化攻克弱项，精准提高成绩</div>
                <div class="banf_img"><img src="./image/teacherb.png" alt=""></div>
              </div>
            </div>
            
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banf_box">
            <div class="banf_top">讲师介绍 <div class="banf_val">中新汉语全明星讲师阵容</div></div>
            <div class="banf_bom">
              <div class="banf_bt">
                <div class="banf_name">蒋佳欣老师</div>
                <div class="banf_li">- 中新汉语新加坡小学金牌讲师</div>
                <div class="banf_li">- 专注新加坡小学华文综合成绩提高</div>
                <div class="banf_li">- 3年教育从业经验、2000+课时线上教学时长</div>
                <div class="banf_li"> - 擅长游戏场景化教学，引导孩子提高学习兴趣</div>
                <div class="banf_img"><img src="./image/teacherc.png" alt=""></div>
              </div>
            </div>
            
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banf_box">
            <div class="banf_top">讲师介绍 <div class="banf_val">中新汉语全明星讲师阵容</div></div>
            <div class="banf_bom">
              <div class="banf_bt">
                <div class="banf_name">陈玲老师</div>
                <div class="banf_li">- 中新汉语新加坡小学金牌讲师  </div>
                <div class="banf_li">- 专注新加坡小学 P1-P3年级，华文基础水平提升</div>
                <div class="banf_li">- 4年教育从业经验、2000+课时教学时长</div>
                <div class="banf_li">- 擅长感知孩子的学习疑难点，针对性讲解提高</div>
                <div class="banf_img"><img src="./image/teacherd.png" alt=""></div>
              </div>
            </div>
            
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="banf_box">
            <div class="banf_top">讲师介绍 <div class="banf_val">中新汉语全明星讲师阵容</div></div>
            <div class="banf_bom">
              <div class="banf_bt">
                <div class="banf_name">小圈老师</div>
                <div class="banf_li">- 中新汉语新加坡小学金牌讲师</div>
                <div class="banf_li">- 专注新加坡小学华文综合成绩提升</div>
                <div class="banf_li">- 2 年教育从业经验，1000+课时教学时长</div>
                <div class="banf_li">- 擅长 1V1 教学，定制化提升孩子的华文成绩</div>
                <div class="banf_img"><img src="./image/teachere.png" alt=""></div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="banf_next" @click="reviewsClick('next')"></div>
    </div>
    <!-- 第七屏 -->
    <div id="section7" class="bang">
      <div class="contentc">
        <div class="title">办公环境</div>
        <div class="bang_con">
          <div class="bang_top">
            <img v-if="bangIndex==0" src="./image/environa.jpg" alt="">
            <img v-else-if="bangIndex==1" src="./image/environb.jpg" alt="">
            <img v-else src="./image/environc.jpg" alt="">
          </div>
          <div class="bang_type">
            <div class="bang_lia" :class="{bang_lid:bangIndex==0}" @click="bangIndex=0"></div>
            <div class="bang_l"></div>
            <div class="bang_lib" :class="{bang_lie:bangIndex==1}" @click="bangIndex=1"></div>
            <div class="bang_l"></div>
            <div class="bang_lic" :class="{bang_lif:bangIndex==2}" @click="bangIndex=2"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第八屏 -->
    <div id="section8" class="banh">
      <div class="banh_con">
        <div class="title">公司简介</div>
        <div class="banh_box">
          <img src="./image/banha.png" alt="">
          <div class="banh_text">我们的前身是 10 年的中国在线教育企业，2022 年开始在新加坡做本土化的学科教育，目前主要针对小学华文作文版块进行辅导。我们主打的优势是以新加坡 MOE 考试大纲为基础，在此之上，结合中国灵活有趣的教学方式进行授课。<div>课程结合大量的情景演绎以及结构训练，让孩子主动参与表演，并进行互动，从而理解每一个知识点和内容。打破传统的死记硬背的方式，并以培养孩子的兴趣为首要原则。通过创新的授课体系，来快速提高孩子的考试分数。</div></div>
        </div>
        <div class="banh_bot"><img src="./image/banhb.png" alt=""></div>
      </div>
    </div>
    <!-- 第九屏 -->
    <div id="section9" class="banr">
      <div class="banr_top">
        <div class="titlec">联系我们</div>
        <div class="banr_box">
          <div class="banr_tit"><img src="./image/banra.png" alt="">地址</div>
          <div class="banr_val">中国：中国 四川省 成都市 高新区 天祥广场 B 座 4406 <br /> 新加坡： 18 BOON LAY WAY #10-138 TRADEHUB 21 SINGAPORE (609966)</div>
          <div class="banr_bom">
            <div class="banr_bm">
              <div class="banr_tit"><img src="./image/banrb.png" alt="">电话</div>
              <div class="banr_val">+65 9685 7958</div>
            </div>
            <div class="banr_bm">
              <div class="banr_tit"><img src="./image/banrc.png" alt="">邮箱</div>
              <div class="banr_val">admin@zxmandarin.com</div>
            </div>
          </div>
          <div class="banr_bt"><img src="./image/banrd.png" alt=""></div>
        </div>
      </div>
    </div>

    
    
    <!-- 尾部 -->
    <footer class="foot">MJS SGP BRANCH PTE.LTD 版权所有</footer>
  </div>
</template>

<script>
// import { getVideo } from "@/assets/api/index";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper' // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
    data(){
        return{
          navList:[
            {
              name:'首页',
              url:'section1'
            },
            {
              name:'我们的优势',
              url:'section2'
            },
            {
              name:'我们的特色',
              url:'section3'
            },
            {
              name:'课堂展示',
              url:'section4'
            },
            {
              name:'课程简介',
              url:'section5'
            },
            {
              name:'讲师介绍',
              url:'section6'
            },
            {
              name:'办公环境',
              url:'section7'
            },
            {
              name:'公司简介',
              url:'section8'
            },
            {
              name:'联系我们',
              url:'section9'
            },
          ],
          navIndex:0,
          isShow:false,
          swiperOptions: {
            slidesPerView : 3,
            spaceBetween : 24,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            loop: true,
            autoplay:true 
          },
          bandList:[
            {
              name:'案例 1',
            },
            {
              name:'案例 2',
            },
            {
              name:'案例 3',
            }
          ],
          bandIndex:0,
          isplay:false,
          baneList:[
            {
              name:'PSLE 专项课程'
            },
            {
              name:'综合课程（同步学校进度）'
            }
          ],
          baneIndex:0,
          isdialoge:false,
          dialogeList:{},
          dialogeLista:{
            title:'PSLE 作文专项提分课',
            val:[
              'PSLE看图/命题集中训练',
              '拿分技巧解析',
              '五大写作手法专项训练',
              '10大主题系列专项训练'
            ],
            characteristic:'孩子是否总是上下文不搭配？缺乏中间过渡情节描写？好词好句死记硬背？这门课程为作文专项课程，10 年经验课研老师提炼 PSLE 所有华文作文题目，以及创作方法。分为 10 种类型，5 种写作手法。可以针对作文版块快速提分。目前将近 300 名学员，好评率 97%。课堂上强调和孩子的沟通互动，引导孩子先进行表达，老师才会进行纠错。主张先口头输出，再文字创作的教学理念。'
          },
          dialogeListb:{
            title:'PSLE 阅读理解提分课',
            val:[
              '精讲PSLE阅读理解各种题型',
              '精练GEP名校试卷+历年PSLE试卷的部分真题',
              'P4、P5衔接式向P6阅读理解题型训练转变',
              '通过培养孩子对文字理解能力和思考能力',
              '让学生在短期内就能掌握：'
            ],
            check:[
              'PSLE阅读应用分析',
              'PSLE理解问答技巧',
              'PSLE阅读思考能力的'
            ],
            characteristic:'孩子阅读理解总是不该错的题错掉？总是无法理解文章的含义？书面互动总是写不好？这全部都是方法问题。这门课程针对Q21-Q40题做出归纳总结，针对每一类题型整理出适合孩子的答题方法。通过审、定、择三步法，让孩子更好的去解决每一种题型。通过正确的答题技巧的学习，来提高阅读理解的分数。'
          },
          dialogeListc:{
            title:'创意作文写作课',
            val:[
              '积累成语俗语使用方法',
              '写作技巧/修辞手法专项训练',
            ],
            characteristic:'孩子是否总是上下文不搭配？缺乏中间过渡情节描写？好词好句死记硬背？这门课程为作文专项课程，10 年经验科研老师提炼 PSLE 所有华文作文主题，以及创作方法。分为 10 种类型，5 种创作方法。这门课程针对基础不好的孩子，专门开发。一节理论一节实践，交叉进行，让孩子讲练结合，直接将学到的知识用到实际创作中。同时技法讲解也会更加细致，防止孩子一学就会，扭头就忘的尴尬情况。'
          },
          dialogeListd:{
            title:'基础作文综合课',
            tle:'（基础字词、兴趣培养）',
            val:[
              '全面跟进课本教材',
              '一笔一画带写生字',
              '听说结合，课堂练习口语交际',
              '拼音识读，夯实华文基础',
              '看图说话写作训练'
            ],
            characteristic:'P1-P2 是孩子打基础的阶段，学习是一个循序渐进的过程。这个阶段孩子的词汇量薄弱，补习的重点是如何让孩子不抗拒华文，让孩子主动学习华文，并且对华文产生浓厚的兴趣。这个阶段的课程，课堂氛围灵活，课程内容以课本内容为基础，延展有趣的知识点，让孩子在理解课本内容的基础上，对华文产生更加浓厚的兴趣。同时也针对基础的字词以及表达方式进行提高训练。'
          },
          dialogeListe:{
            title:'进阶作文综合课',
            tle:'（基础字词、阅读理解、归纳总结）',
            val:[
              '课本词句解析',
              '好词好句拓展积累',
              '写作技巧训练，从字到句再到段落',
              '范交解析，从模仿写作到独立思考',
            ],
            characteristic:'P3-P4阶段，孩子对于华文已经有了一定的词汇量基础，并且课本教材也开始向多元化的方向开始延展，这个阶段主要针对孩子的理解能力、观察能力、总结能力进行提升，同时针对孩子的课外词汇进行积累补充，为即将到来的 P5P6年级做准备。'
          },
          dialogeListf:{
            title:'强化作文综合课',
            tle:'（字词复习、阅读理解、归纳总结、高分词句、优化表达）',
            val:[
              '高级好词好句积累',
              '写作技巧拔高训练，强化细节描写',
              '交章结构训练，构建行交逻辑',
              'PSLE题型训练',
            ],
            characteristic:'P5-P6阶段， 华文的基础积累已经达标，并且开始针对 PSLE 做准备，全力冲击离校考试。这个阶段将主要针对 PSLE 的考试做训练，开始强化理解能力、归纳总结能力、表达方式。并且针对不同的作文题目形式进行训练，增加命题作文、情景作文和完成作文的训练。'
          },
          bangIndex:0,
          isclose:false
        }
    },
    components:{
      Swiper,
      SwiperSlide
    },
    created(){
        // getVideo(1)
    },
    mounted(){
      window.addEventListener('scroll', this.handleScroll);
      // this.$refs.videoa.addEventListener('play', this.handlePlay);
      // this.$refs.videoa.addEventListener('pause', this.handlePause);
    },
    methods:{
        // async getVideo(type) {
        //   const res = await getVideo({
        //     type:type
        //   });
        //   if (res.code==200) {
        //         console.log(res.data);
        //   }
        // },
        onNavClick(url,index){
          let scroll_top = document.querySelector(`#${url}`).offsetTop-80
          window.scroll({
            top:scroll_top,
            behavior:'smooth'
          })
          this.isclose=false
          // document.querySelector(`#${url}`).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          // this.navIndex = index
        },
        handleScroll() {
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          this.isShow = scrollTop > 30 ? true : false 
          let scroll_top1 = document.querySelector('#section1').offsetTop-80
          let scroll_top2 = document.querySelector('#section2').offsetTop-80
          let scroll_top3 = document.querySelector('#section3').offsetTop-80
          let scroll_top4 = document.querySelector('#section4').offsetTop-80
          let scroll_top5 = document.querySelector('#section5').offsetTop-80
          let scroll_top6 = document.querySelector('#section6').offsetTop-80
          let scroll_top7 = document.querySelector('#section7').offsetTop-80
          let scroll_top8 = document.querySelector('#section8').offsetTop-80
          let scroll_top9 = document.querySelector('#section9').offsetTop-80
          // if(scrollTop>=scroll_top&&scrollTop<=scroll_top1){
          //   this.navIndex = 1
          // }
          if(scrollTop>=scroll_top9){
            this.navIndex = 8
          }else if(scrollTop>=scroll_top8&&scrollTop<=scroll_top9){
            this.navIndex = 7
          }else if(scrollTop>=scroll_top7&&scrollTop<=scroll_top8){
            this.navIndex = 6
          }else if(scrollTop>=scroll_top6&&scrollTop<=scroll_top7){
            this.navIndex = 5
          }else if(scrollTop>=scroll_top5&&scrollTop<=scroll_top6){
            this.navIndex = 4
          }else if(scrollTop>=scroll_top4&&scrollTop<=scroll_top5){
            this.navIndex = 3
          }else if(scrollTop>=scroll_top3&&scrollTop<=scroll_top4){
            this.navIndex = 2
          }else if(scrollTop>=scroll_top2&&scrollTop<=scroll_top3){
            this.navIndex = 1
          }else{
            this.navIndex = 0
          }

        },
        prevFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slidePrev();
        },
        nextFn(name) {
          const swiper = this.$refs[name].$swiper;
          swiper.slideNext();
        },
        onbandClick(index){
          this.bandIndex = index
          this.isplay = false
        },
        handlePlay(){
          // this.isplay=true
          // this.$refs.videoa.play()
          if(this.$refs.videoa.paused){
            this.isplay=true
            this.$refs.videoa.play()
          }else{
            this.isplay=false
            this.$refs.videoa.pause()
          }
        },
        onbaneClick(index){
          this.baneIndex = index
        },
        handDialoge(name){
          this.isdialoge = true
          this.dialogeList=name
        },
        reviewsClick(val) {
          if(val === 'next') {
            this.$refs.bane.next()
          } else {
            this.$refs.bane.prev()
          }
        },
    }
}
</script>

<style lang='less' scoped>
.home{
  width: 100%;
  padding: 0;
  margin: 0;
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 120px;
  box-sizing: border-box;
}
.header_scrolled{
  background: #0B215C;
  transition: all 1s;
}
.head_img{
  width: 24px;
  height: 24px;
  background: url('./image/menta.png');
}
.head_img:hover{
  background: url('./image/mentb.png');
}
.head_close{
  width: 24px;
  height: 24px;
  background: url('./image/closea.png');
}
.head_close:hover{
  background: url('./image/closeb.png');
}


.headment{
  position: relative;
  display: none;
  width: 240px;
  height: 80px;
  padding-left: 100px;
  padding-top: 28px;
  box-sizing: border-box;
}
.head_mentbox{
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 99;
  width: 240px;
  height: 1000px;
  padding-right: 80px;
  padding-left: 70px;
  padding-top: 30px;
  box-sizing: border-box;
}
.mentli{
  text-align: end;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #FFFFFF;
  cursor: pointer;
  margin-bottom: 32px;
}
.mentli:hover{
  color: #FA7814;
}
.isment{
  color: #FA7814;
}


.headnav{
  display: flex;
  align-items: center;
}
.navli{
  position: relative;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #FFFFFF;
  margin-right: 32px;
  cursor: pointer;
}
.navli:last-child{
  margin-right: 0;
}
.isnav::after{
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  display: inline-block;
  width: 16px;
  height: 4px;
  background: #FA7814;
}

.banner{
  width: 100%;
  height: 900px;
  background: url('./image/banner.jpg') no-repeat;
  background-size: cover;
  background-position: 50%;
  text-align: center;
  padding-top: 250px;
  box-sizing: border-box;
}
.banner_tit{
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 48px;
}
.banner_val{
  font-size: 80px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 100px;
}
.banner_bot{
  margin-top: 100px;
}
.banner_bot img{
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.banb{
  width: 100%;
  height: 900px;
  background: url('./image/banb.jpg') no-repeat;
  text-align: center;
  padding-top: 100px;
  box-sizing: border-box; 
}
.content{
  // width: 1440px;
  // width: 100%;
  width: 1200px;
  margin: auto;
}
.contentb{
  width: 1280px;
  margin: auto;
}
.title{
  position: relative;
  font-size: 48px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  margin-bottom: 68px;
  text-align: center;
}
.title::after{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 48px;
  height: 4px;
  background: #FA7814;
}
.titleb{
  position: relative;
  font-size: 48px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  margin-bottom: 68px;
  text-align: center;
}
.banb_con{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.banb_box{
  width: calc((100% - 80px)/4);
  height: 248px;
  background: #FFFFFF;
  box-shadow: 4px 6px 10px 0px rgba(144,147,153,0.1);
  border-radius: 8px 8px 8px 8px;
  padding-top: 34px;
  box-sizing: border-box;
  text-align: center;
  // margin-right: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}
.banb_box:nth-child(4n){
  margin-right: 0;
}
.banb_box:hover{
  box-shadow: 8px 16px 20px 0px rgba(144,147,153,0.16);
}
.banb_img img{
  width: 110px;
  height: 110px;
}
.banb_text{
  margin-top: 16px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: center;
}
.banc{
  width: 100%;
  height: 900px;
  background: url('./image/banc.jpg') no-repeat;
  text-align: center;
  padding-top: 150px;
  box-sizing: border-box; 
}
.banc_con{
  position: relative;
}
.swipera{
  width: 1200px;
}
.banc_box{
  width: 400px;
  height: 350px;
  background: #FFFFFF;
  box-shadow: 4px 6px 10px 0px rgba(144,147,153,0.1);
  border-radius: 8px 8px 8px 8px;
}
.banc_img{
  margin-bottom: 32px;
}
.banc_img img{
  width: 100%;
}
.banc_text{
  text-align: center;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
.mjs_arrow{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
}
.prev{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: url('./image/preva.png') no-repeat;
}
.prev:hover{
  background: url('./image/prevb.png') no-repeat;
}
.next{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: url('./image/nexta.png') no-repeat;
}
.next:hover{
  background: url('./image/nextb.png') no-repeat;
}

.band{
  width: 100%;
  height: 900px;
  background: #F5F7FC;
  text-align: center;
  padding-top: 60px;
  box-sizing: border-box; 
}
.band_type{
  width: 420px;
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #FA7814;
  margin: auto;
  display: flex;
  overflow: hidden;
}
.band_li{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #FA7814;
  text-align: center;
  flex: 1;
  line-height: 32px;
  cursor: pointer;
}
.band_active{
  background: #FA7814;
  color: #FFFFFF;
}
.band_top{
  position: relative;
  width: 841px;
  height: 450px;
  margin: 48px auto;
  background: url('./image/model.png');
  padding-top: 10px;
  box-sizing: border-box;
}
.band_text{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
}
.videoI{
  width: 664px;
  height: 386px;
}
.band_play{
  position: absolute;
  top: 15px;
  left: 88px;
  width: 79%;
  height: 84%;
  background: gray;
  text-align: center;
  padding-top: 130px;
  box-sizing: border-box;
}
.band_play img{
  width: 85px;
  height: 85px;
}

.bane{
  width: 100%;
  height: 900px;
  background: #F5F7FC;
  text-align: center;
  padding-top: 45px;
  box-sizing: border-box; 
}
.bane_type{
  width: 520px;
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #FA7814;
  margin: auto;
  display: flex;
  overflow: hidden;
  margin-bottom: 40px;
}
.bane_li{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #FA7814;
  text-align: center;
  flex: 1;
  line-height: 32px;
  cursor: pointer;
}
.bane_active{
  background: #FA7814;
  color: #FFFFFF;
}
.bane_top{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bane_box{
  position: relative;
  width: calc((100% - 40px)/3);
  height: 300px;
  background: #FFFFFF;
  padding: 40px 24px 44px;
  box-sizing: border-box;
  box-shadow: 4px 6px 10px 0px rgba(144,147,153,0.1);
  border-radius: 8px 8px 8px 8px;
}
.bane_box span{
  font-weight: 700;
  color: #FA7814;
}
.bane_box:hover{
  box-shadow: 8px 16px 20px 0px rgba(144,147,153,0.16);
}
.bane_box:hover .bane_btn{
  background: #FA7814;
  color: #FFFFFF;
}
.bane_title{
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  line-height: 28px;
  margin-bottom: 24px;
}
.bane_text{
  font-size: 14px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
}
.bane_body{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bane_i{
  width: calc((100% - 12px)/2);
  height: 24px;
  background: #F5F5F5;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 24px;
  margin-bottom: 12px;
  text-align: start;
  padding-left: 28px;
  box-sizing: border-box;
}
.bane_btn{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 240px;
  height: 40px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #FA7814;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #FA7814;
  cursor: pointer;
}
.bane_bom{
  width: 100%;
  height: 200px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  padding: 32px 100px;
  box-sizing: border-box;
  margin-top: 60px;
}
.bane_tit{
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  margin-bottom: 16px;
}
.bane_val{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: justify;
  text-indent: 32px;
}

.banedialog{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  z-index: 9;
}
.dialoge_box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 700px;
  height: 560px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  padding: 60px 24px 48px;
  box-sizing: border-box;
}
.dialoge_title{
  font-size: 32px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  text-align: center;
  margin-bottom: 24px;
}
.dialoge_body{
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 26px;
  padding-left: 50px;
  box-sizing: border-box;
}
.dialoge_body::-webkit-scrollbar{
  width:2px;
  background-color:#E5E5E5;
}
.dialoge_tit{
  font-size: 20px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  line-height: 24px;
  margin-bottom: 8px;
}
.dialoge_val{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: justify;
}
.dialoge_btn{
  width: 160px;
  height: 40px;
  background: #FA7814;
  border-radius: 20px 20px 20px 20px;
  margin: auto;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #F5F5F5;
  text-align: center;
  line-height: 40px;
  margin-top: 55px;
  cursor: pointer;
}
.dialoge_check{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}
.dialoge_img{
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  margin-right: 12px;
  margin-bottom: 12px;
}
.dialoge_img img{
  margin-right: 2px;
}
.dialoge_tle{
  text-align: center;
  font-size: 16px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  margin-top: -10px;
  margin-bottom: 20px;
}

.banf{
  position: relative;
  width: 100%;
  padding: 80px 0 60px;
  box-sizing: border-box;
  background: #FFFFFF;
}
.banf_box{
  position: relative;
  width: 100%;
  height: 700px;
}
.banf_top{
  width: 1280px;
  margin: auto;
  font-size: 48px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  box-sizing: border-box;
}
.banf_val{
  margin-top: 20px;
  margin-bottom: 85px;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #666666;
}
.banf_bom{
  width: 100%;
  height: 480px;
  background: url('./image/banf.jpg') no-repeat;
  background-size: 100%;
}
.banf_bt{
  position: relative;
  width: 1280px;
  margin: auto;
  padding-top: 108px;
  box-sizing: border-box;
}
.banf_name{
  font-size: 32px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  margin-bottom: 30px;
}
.banf_li{
  font-size: 28px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.banf_img{
  position: absolute;
  top: -182px;
  right: 0;
}
.banf_prev{
  position: absolute;
  top: 59%;
  left: 8%;
  z-index: 2;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: url('./image/preva.png') no-repeat;
  cursor: pointer;
}
.banf_prev:hover{
  background: url('./image/prevb.png') no-repeat;
}
.banf_next{
  position: absolute;
  top: 59%;
  right: 8%;
  z-index: 2;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: url('./image/nexta.png') no-repeat;
  cursor: pointer;
}
.banf_next:hover{
  background: url('./image/nextb.png') no-repeat;
}
.contentc{
  width: 1080px;
  margin: auto;
}
.bang{
  width: 100%;
  height: 900px;
  background: url('./image/bang.jpg') no-repeat;
  text-align: center;
  padding-top: 60px;
  box-sizing: border-box; 
}
.bang_con{
  margin-top: -20px;
}
.bang_top{
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 40px;
}
.bang_top img{
  width: 100%;
  height: 540px;
}
.bang_type{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.bang_lia{
  width: 48px;
  height: 48px;;
  border-radius: 50%;
  background: url('./image/banga.png');
}
.bang_lib{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: url('./image/bangb.png');
}
.bang_lic{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: url('./image/bangc.png');
}
.bang_lid{
  width: 64px;
  height: 64px;
  background: url('./image/bangd.png');
}
.bang_lie{
  width: 64px;
  height: 64px;
  background: url('./image/bange.png');
}
.bang_lif{
  width: 64px;
  height: 64px;
  background: url('./image/bangf.png');
}
.bang_l{
  width: 90px;
  height: 2px;
  background: #999999;
  margin: 0 35px;
}
.banh{
  width: 100%;
  height: 900px;
  background: url('./image/banh.jpg') no-repeat;
  text-align: center;
  padding: 70px 0;
  box-sizing: border-box; 
}
.banh_con{
  position: relative;
  width: 1440px;
  height: 720px;
  background: #FFFFFF;
  box-shadow: 10px 10px 30px 0px rgba(144,147,153,0.14);
  border-radius: 8px 8px 8px 8px;
  margin: auto;
  padding: 108px 193px;
  box-sizing: border-box;
}
.banh_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banh_text{
  width: 424px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  text-indent: 40px;
  text-align: justify;
}
.banh_bot{
  position: absolute;
  bottom: 0;
  right: 0;
}

.banr{
  width: 100%;
  background: #F2F5FA;
  padding-bottom: 408px;
  box-sizing: border-box;
}
.banr_top{
  position: relative;
  width: 100%;
  height: 560px;
  background: url('./image/banr.jpg');
  background-size: 100%;
  padding-top: 140px;
  box-sizing: border-box;
}
.titlec{
  position: relative;
  font-size: 48px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 68px;
  text-align: center;
}
.titlec::after{
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 48px;
  height: 4px;
  background: #FA7814;
}
.banr_box{
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  height: 460px;
  background: #FFFFFF;
  box-shadow: 10px 10px 30px 0px rgba(144,147,153,0.14);
  border-radius: 4px 4px 4px 4px;
  padding: 88px 80px;
  box-sizing: border-box;
}
.banr_tit{
  font-size: 32px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 700;
  color: #333333;
  line-height: 48px;
  margin-bottom: 8px;
}
.banr_tit img{
  margin-right: 16px;
}
.banr_val{
  padding-left: 64px;
  box-sizing: border-box;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.banr_bom{
  display: flex;
  margin-top: 68px;
}
.banr_bm{
  margin-right: 248px;
}
.banr_bt{
  position: absolute;
  top: -80px;
  right: 0;
}
::v-deep .is-active .el-carousel__button{
  background: #FA7814;
}
.foot{
  width: 100%;
  height: 80px;
  background: #1A2D52;
  font-size: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #B2B2B2;
  text-align: center;
  line-height: 80px;
}

// 适配

@media screen and (max-width: 1399px) {
  .fenq{
    left: 40px !important;
  }
  .lvq{
    left: 60px !important;
  }
  .ziq{
    right: 50px;
  }
  .huq{
    right: 200px;
  }
  .getLesson_img{
    left: 0px;
  }
  .getLesson_b{
    padding-left: 130px;
  }
}

@media screen and (max-width: 1299px) {
  .header{
    padding: 0 0 0 60px;
  }
  .headnav{
    display: none;
  }
  .headment{
    display: block;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1399px) {
  .header{
    padding: 0 60px;
  }
  .navli{
    margin-right: 20px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1479px) {
  .header{
    padding: 0 60px;
  }
  .navli{
    margin-right: 30px;
  }
}

@media screen and (min-width: 1480px) and (max-width: 1919px) {
  .header{
    padding: 0 120px;
  }
}


</style>